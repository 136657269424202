<template>
  <div class="detail-activity-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Activity' }"
        >活動管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>活動內容</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <div class="flex-end" style="padding: 12px 0">
        <el-button type="info" @click="show_detail = !show_detail">{{
          !show_detail ? "內容展開" : "內容收起"
        }}</el-button>
      </div>
      <div
        class="content text-center"
        v-if="!show_detail"
        @click="show_detail = !show_detail"
      >
        內容過多，請點擊展開
      </div>
      <el-form :model="activity_form" label-position="top" v-show="show_detail">
        <el-form-item label="活動照片">
          <el-upload
            class="photo-uploader"
            action=""
            :show-file-list="false"
            accept=".jpeg,.jpg,.png"
            disabled
          >
            <img
              v-if="preview_url"
              :src="preview_url"
              class="photo"
              alt="photo"
            />
            <i v-else class="el-icon-plus photo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="報名起始時間" prop="start_date">
          <el-date-picker
            v-model="activity_form.start_date"
            type="datetime"
            placeholder="選擇起始時間"
            disabled
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="報名結束時間" prop="end_date">
          <el-date-picker
            v-model="activity_form.end_date"
            type="datetime"
            placeholder="選擇結束時間"
            disabled
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活動場次時間 " prop="activity_sessions">
          <template #label>
            <span>活動場次時間</span>&nbsp;
            <span style="font-size: 12px"
              >(此區排序會依照場次開始時間自行排序)</span
            >
          </template>
          <div
            v-for="(_, index) in activity_form.activity_sessions"
            :key="index"
            class="activity-sessions-block"
          >
            <div>場次{{ idx_array[index] }}</div>
            <div class="time-picker">
              <el-date-picker
                v-model="activity_form.activity_sessions[index].start_time"
                type="datetime"
                placeholder="選擇場次起始時間"
                disabled
              >
              </el-date-picker>
              <el-date-picker
                v-model="activity_form.activity_sessions[index].end_time"
                type="datetime"
                placeholder="選擇場次結束時間"
                disabled
              >
              </el-date-picker>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="中文標題" prop="title_zh">
          <el-input v-model="activity_form.title_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文標題" prop="title_en">
          <el-input v-model="activity_form.title_en" disabled></el-input>
        </el-form-item>
        <el-form-item label="中文介紹" prop="introduction_zh">
          <div ref="js-editor-introduction_zh"></div>
        </el-form-item>
        <el-form-item label="英文介紹" prop="introduction_en">
          <div ref="js-editor-introduction_en"></div>
        </el-form-item>
        <el-form-item label="中文地點" prop="location_zh">
          <el-input v-model="activity_form.location_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文地點" prop="location_en">
          <el-input v-model="activity_form.location_en" disabled></el-input>
        </el-form-item>
        <el-form-item label="活動類別" prop="category_id">
          <div v-if="activity_form.category !== null" style="color: #c0c4cc">
            {{ activity_form.category.name_zh || "" }}

            <template v-if="activity_form.subcategory !== null">
              - {{ activity_form.subcategory.name_zh || "" }}
            </template>
          </div>
          <div v-else style="color: #c0c4cc">N/A</div>
        </el-form-item>
        <el-form-item label="最大參與人數" prop="max_participant">
          <el-input
            v-model.number="activity_form.max_participant"
            disabled
          ></el-input>
        </el-form-item>
        <div v-for="number in [1, 2, 3]" :key="number">
          <el-form-item
            :label="`活動問題${number}`"
            :prop="`question_${number}`"
          >
            <div>
              <div style="margin-bottom: 12px">
                <span style="margin-right: 12px; color: #606266">是否必填</span>
                <el-radio-group
                  :value="activity_form[`question_${number}`].required"
                  size="small"
                >
                  <el-radio :label="true" :border="true" disabled>是</el-radio>
                  <el-radio :label="false" :border="true" disabled>否</el-radio>
                </el-radio-group>
              </div>
              <div style="margin-bottom: 12px">
                <span style="margin-right: 12px; color: #606266">類型</span>
                <el-select
                  :value="activity_form[`question_${number}`].type"
                  @change="activity_form[`question_${number}`].options = ['']"
                  disabled
                >
                  <el-option
                    v-for="{ label, value } in question_types"
                    :key="value"
                    :label="label"
                    :value="value"
                  ></el-option>
                </el-select>
              </div>
              <div style="margin-bottom: 12px; display: flex">
                <span class="question-title">問題</span>
                <el-input
                  :value="activity_form[`question_${number}`].question"
                  disabled
                ></el-input>
              </div>
              <div style="display: flex">
                <span class="question-title">選項</span>
                <div style="margin-bottom: 12px">
                  <div
                    v-for="(_, index) in activity_form[`question_${number}`]
                      .options"
                    :key="index"
                    class="question-option"
                  >
                    <el-input
                      :value="
                        activity_form[`question_${number}`].options[index]
                      "
                      disabled
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
        <el-form-item label="活動聯絡人信箱" prop="contact_email">
          <el-input
            v-model="activity_form.contact_email"
            maxlength="256"
            show-word-limit
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="承辦人備註" prop="comment">
          <div ref="js-editor-comment" class="editor"></div>
        </el-form-item>
        <el-form-item label="線上模式" prop="live">
          <el-radio-group v-model="activity_form.live" size="small">
            <el-radio :label="true" :border="true" disabled>是</el-radio>
            <el-radio :label="false" :border="true" disabled>否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="網址連結" prop="live_stream_url">
          <el-input v-model="activity_form.live_stream_url" disabled></el-input>
        </el-form-item>
        <el-form-item label="報名連結" prop="registration_url">
          <el-input
            v-model="activity_form.registration_url"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="成果網站" prop="final_project_url">
          <el-input
            v-model="activity_form.final_project_url"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button
              type="warning"
              @click="$router.push({ name: 'Activity' })"
              >回主頁</el-button
            >
            <el-button
              type="primary"
              @click="
                $router.push({
                  name: 'EditActivity',
                  params: { id: activityId },
                })
              "
              >編輯
            </el-button>
          </div>
        </el-form-item>
      </el-form>

      <speaker-table
        :speakers="speakers"
        @get:activity="handleGetActivity"
      ></speaker-table>

      <keyword-table
        :data="keywords"
        title="活動關鍵字列表"
        :create-method="createActivityKeywords"
        :delete-method="deleteActivityKeywords"
        @get:keywords="handleGetActivity"
      ></keyword-table>
    </div>
  </div>
</template>

<script>
import {
  getActivity,
  createActivityKeywords,
  deleteActivityKeywords,
} from "@/api/activity";
import { getActivityCategories } from "@/api/activity-category";
import SpeakerTable from "@/views/activity/main/component/SpeakerTable";
import KeywordTable from "@/components/Keyword/KeywordTable.vue";
import E from "wangeditor";

const IDX_ARRAY = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];

export default {
  name: "DetailActivity",
  components: { SpeakerTable, KeywordTable },
  data() {
    return {
      activity_form: {
        photo: "",
        start_date: "",
        end_date: "",
        title_zh: "",
        title_en: "",
        introduction_zh: "",
        introduction_en: "",
        location_zh: "",
        location_en: "",
        category: "",
        subcategory: "",
        max_participant: 0,
        live: false,
        live_stream_url: "",
        registration_url: "",
        final_project_url: "",
        question_1: {
          required: true,
          question: "",
          type: "SIMPLE",
          options: [""],
        },
        question_2: {
          required: true,
          question: "",
          type: "SIMPLE",
          options: [""],
        },
        question_3: {
          required: true,
          question: "",
          type: "SIMPLE",
          options: [""],
        },
        contact_email: "",
        comment: "",
        activity_sessions: [{ start_time: null, end_time: null }],
      },
      categories: [],
      subcategories: [],
      show_detail: false,
      speakers: [],
      keywords: [],
      preview_url: "",
      idx_array: IDX_ARRAY,
      createActivityKeywords,
      deleteActivityKeywords,
      question_types: [
        { label: "簡答", value: "SIMPLE" },
        { label: "單選", value: "SINGLE_CHOICE" },
        { label: "多選", value: "MULTIPLE_CHOICE" },
      ],
    };
  },
  computed: {
    activityId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.handleGetCategories();
  },
  methods: {
    handleInitEditor() {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith("js-editor")) {
          const editor = new E(this.$refs[ref]);
          const key = ref.replace("js-editor-", "");

          editor.config.onchange = (html) => {
            this.activity_form[key] = html;
          };

          // using base64 to store image
          editor.config.uploadImgShowBase64 = true;
          // disable online image url
          editor.config.showLinkImg = false;
          // change language to en
          editor.config.lang = "en";

          // import i18n
          editor.i18next = window.i18next;

          editor.config.placeholder = "請輸入內容";
          editor.config.menus = [
            "fontSize",
            "bold",
            "head",
            "link",
            "italic",
            "strikeThrough",
            "underline",
            "image",
          ];

          editor.create();
          editor.disable();

          editor.txt.html(this.activity_form[key]);
        }
      });
    },
    async handleGetActivity() {
      const { activity_sessions, ...activity } = await getActivity(
        this.activityId,
        { relation: true }
      );

      this.activity_form = { ...this.activity_form, ...activity };

      if (activity_sessions.length > 0) {
        this.activity_form.activity_sessions = activity_sessions;
      }

      this.activity_form.contact_email =
        activity.contact_email === "null" ? "" : activity.contact_email;

      this.preview_url = `${process.env.VUE_APP_IMAGE_API}`.endsWith("/")
        ? `${process.env.VUE_APP_IMAGE_API}${this.activity_form.photo}`
        : `${process.env.VUE_APP_IMAGE_API}/${this.activity_form.photo}`;

      this.speakers = this.activity_form.speakers;

      this.keywords = this.activity_form.keywords;
    },
    async handleGetCategories() {
      this.categories = await getActivityCategories();

      await this.handleGetActivity();
      this.handleInitEditor();
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-activity-block {
  .content {
    margin: 12px 0;
    padding: 24px 0;
    border: 1px solid #606266;
    border-radius: 12px;
    color: var(--primary-gray-color);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      border: 1px solid #409eff;
      color: #409eff;
    }
  }

  .activity-sessions-block {
    .time-picker {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }
  }

  .question-title {
    margin-right: 12px;
    color: #606266;
    display: block;
    width: 30px;
  }

  .question-option {
    display: flex;
    width: 400px;
    gap: 8px;
    margin-bottom: 12px;
  }

  ::v-deep.photo-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }

  ::v-deep.photo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1200px;
    height: 360px;
    line-height: 360px;
    text-align: center;
  }

  ::v-deep.photo {
    width: auto;
    height: 360px;
    display: block;
  }

  ::v-deep .w-e-content-preview {
    background-color: #f5f7fa;
    cursor: not-allowed;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #c0c4cc;
    }
  }
}
</style>
