<template>
  <el-dialog
    title="新增活動講者"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form ref="speakerForm" :model="speaker_form" :rules="speaker_rules">
      <el-form-item label="講者列表" prop="speaker_id">
        <el-select v-model="speaker_form.speaker_id" class="w-100">
          <el-option
            v-for="{
              speaker_id,
              name_zh,
              name_en,
              introduction_zh,
            } in speakers"
            :value="speaker_id"
            :label="name_zh"
            :key="speaker_id"
          >
            <div style="float: left; width: 400px" class="text-overflow">
              {{ name_zh }} - {{ introduction_zh }}
            </div>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              name_en
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="warning" @click="handleClose">取消</el-button>
      <el-button type="success" @click="handleSubmit">送出</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getSpeakers } from "@/api/activity-speaker";
import { createActivitySpeaker } from "@/api/activity";

export default {
  name: "CreateSpeakerDialog",
  props: {
    dialogVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      speaker_form: {
        speaker_id: "",
      },
      speaker_rules: {
        speaker_id: [
          { required: true, message: "請選擇講者", trigger: "change" },
        ],
      },
      speakers: [],
    };
  },
  computed: {
    activityId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleGetSpeakers();
  },
  methods: {
    async handleGetSpeakers() {
      this.speakers = await getSpeakers();
    },
    handleSubmit() {
      this.$refs.speakerForm.validate(async (valid) => {
        if (valid) {
          await createActivitySpeaker(
            this.activityId,
            this.speaker_form.speaker_id
          );

          this.$message({
            type: "success",
            message: "創建成功",
          });

          this.handleClose(true);
        }
      });
    },
    handleClose(call) {
      this.$emit("dialog:close", { call });
    },
  },
};
</script>

<style lang="scss" scoped></style>
