<template>
  <div class="speaker-table-block">
    <table-title>
      <p>活動講者</p>

      <el-button @click="dialogVisible.create = true" type="success"
        >新增活動講者</el-button
      >
    </table-title>
    <el-table :data="speakers">
      <el-table-column label="ID" prop="speaker_id" sortable></el-table-column>
      <el-table-column
        label="中文名稱"
        prop="name_zh"
        sortable
      ></el-table-column>
      <el-table-column
        label="英文名稱"
        prop="name_en"
        sortable
      ></el-table-column>
      <el-table-column label="編輯" width="80px">
        <template v-slot="{ row }">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(row.speaker_id)"
            >刪除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div v-if="dialogVisible.create">
      <create-speaker-dialog
        :dialog-visible="dialogVisible.create"
        @dialog:close="handleToggleDialog"
      ></create-speaker-dialog>
    </div>
  </div>
</template>

<script>
import CreateSpeakerDialog from "@/views/activity/main/component/CreateSpeakerDialog";
import { deleteActivitySpeaker } from "@/api/activity";

export default {
  name: "SpeakerTable",
  components: { CreateSpeakerDialog },
  props: {
    speakers: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activityId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      dialogVisible: {
        create: false,
      },
    };
  },
  methods: {
    handleDelete(speaker_id) {
      this.$confirm("是否移除該講者", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteActivitySpeaker(this.activityId, speaker_id);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          this.$emit("get:activity");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
    handleToggleDialog(call) {
      this.dialogVisible.create = !this.dialogVisible.create;
      if (call) {
        this.$emit("get:activity");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
